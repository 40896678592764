/* src/shared/tw.css */
@tailwind base;
@layer base {
  body {
    @apply text-neutral-70;
    @apply body-base;
  }
}
@tailwind components;
@tailwind utilities;

/* src/shared/global.css */

/* temp_stylePlugin:ni:sha-256;8d-Hd7gKUuto0A_9MuuMmE3roDjlHnLXI6NFuAwRSAE */
.unordered__nDCZ3,
.ordered__panI3 {
  & li {
    @apply relative pl-5;
    &:before {
      @apply absolute left-0;
    }
  }
}
.unordered__nDCZ3 {
  & li {
    &:before {
      @apply top-2 h-2 w-2 rounded-full bg-primary-50;
      content: "";
    }
  }
}
.ordered__panI3 {
  counter-reset: li;
  & li {
    &:before {
      @apply top-[0.125rem] text-primary-50;
      content: counter(li);
      counter-increment: li;
    }
  }
}
